<template>
  <div class="dptBonus0">
    <div v-title
         data-title="竞猜活动"></div>
    <div class="imgbox">
      <img :src="`${imgUrl}pcbb/bg-quizactivities.jpg`" />
    </div>

    <div class="rule-big-box">
      <div class="rule-top-img">
        <img src="../assets/img/pcbb/rules_1.png"
             v-if="cueTheme == 'dattheme'" />
        <img :src="`${imgUrl}pcbb/rules.png`"
             v-else />
      </div>
      <div class="rule-middle-img">
        <!-- 活动条件 -->
        <div class="rule-condition">
          <div class="rule-condition-title"></div>
          <div class="rule-acontents">
            <div class="title-item">
              <p>活动说明:</p>
              <span style="color:var(--li-colortext);">
                预测指定赛事进行时会出现的情况，每个会员单次活动只能参与一次。</span>
            </div>
          </div>
        </div>

        <div class="quizactivities-top">
          <div class="quizactivities-content">
            <div class="quizactivities-left">
              <div class="quizactivities-img">
                <img :src="datalist.hostTeamIcon" />
              </div>
              <div class="quizactivities-text">
                {{ datalist.hostTeamName }}
              </div>
            </div>
            <div class="quizactivities-middle">
              <div>{{ datalist.matchTitle }}</div>
              <div>{{ datalist.matchStartTime }}</div>
              <!-- <div class="schedule"></div> -->
              <div>本次比赛奖池礼金:<span style="font-size: 14px;">{{ datalist.bonus }}</span></div>
            </div>
            <div class="quizactivities-left">
              <div class="quizactivities-img"
                   style="border-color:#e9dff9">
                <img :src="datalist.guestTeamIcon" />
              </div>
              <div class="quizactivities-text">
                {{ datalist.guestTeamName }}
              </div>
            </div>
          </div>
        </div>

        <!-- 活动内容 -->
        <div class="rule-content"
             v-for="(item, index) in contentlist"
             :key="index">
          <div class="DptBonus-content">
            <div class="qui-content-box">
              <div v-for="(v, i) in item"
                   :key="i"
                   @click="pitchon(v, index, i)"
                   :class="v.check == true || v.id == datalist.pid ? 'actqui' : ''"
           
                >
                {{ v.name }}
              </div>
            </div>
          </div>
          <div class="DptBonus-right"
               style="margin-bottom:35px">
            <div class="DptBonus-btns"
                 @click="quizactivities(index)"
                 :style="{ backgroungColor: datalist.sj_zt == 0 ? '' : '#999' }">
              {{ datalist.sj_zt == 0 ? '参与竞猜' : '已参与' }}
            </div>
          </div>
        </div>

        <p style="text-align: center;color:var(--li-colortext);font-size: 14px;">我的竞猜记录</p>
        <div class="seamless-box">
          <div class="toptitle">
            <div class="item">竞猜内容</div>
            <div class="item">派彩金额</div>
            <div class="item">竞猜时间</div>
            <div class="item">结果</div>
          </div>
          <div class="seamless-warp2"
               v-if="datalist.applyed_data && datalist.applyed_data.length > 0">
            <div v-for="(item,index) in datalist.applyed_data"
                 :key="index"
                 class="seamless-item">
              <div class="seamless-item1">{{item.name}}</div>
              <div class="seamless-item1">{{item.bouns}}</div>
              <div class="seamless-item1">{{item.created_at}}</div>
              <div class="seamless-item1" :class="item.status == 1 ? 'actcolor1' : item.status == 2 ? 'actcolor2':'' ">
                {{ item.status == 0 ? '待开奖' : item.status == 1 ? '已中奖' :  item.status == 2 ? '未中奖' : ''}}</div>
            </div>
          </div>
          <div v-else
               style="text-align: center;
          padding: 20px;border: 1px solid #eeeeee;">
            暂无竞猜记录
          </div>
        </div>

        <p style="text-align: center;color:var(--li-colortext);font-size: 14px;">中奖结果({{datalist.zj_num}}人已中奖)</p>
        <div class="seamless-box">
          <div class="toptitle">
            <div class="item">比赛</div>
            <div class="item">会员</div>
            <div class="item">竞猜内容</div>
            <div class="item">派彩<br/>金额</div>
            <div class="item">竞猜<br/>时间</div>
            <div class="item">结果</div>
          </div>
          <vue-seamless-scroll :data="listData"
                               :class-option="optionHover"
                               class="seamless-warp"
                               v-if="listData.length > 0"
                               >
            <div v-for="(item,index) in listData"
                 :key="index"
                 class="seamless-item">
              <div class="seamless-item1">{{item.zj_name}}</div>
              <div class="seamless-item1">{{item.zj_user}}</div>
              <div class="seamless-item1">{{item.zj_content}}</div>
              <div class="seamless-item1">{{item.bouns}}</div>
              <div class="seamless-item1">{{item.created_at}}</div>
              <div class="seamless-item1" :class="item.zj_zt == '已中奖' ? 'actcolor1' : item.zj_zt == '未中奖' ? 'actcolor2':'' ">{{item.zj_zt}}</div>
            </div>
          </vue-seamless-scroll>
          <div v-else 
               style="text-align: center;
          padding: 20px;border: 1px solid #eeeeee;">
            暂未开奖
          </div>
        </div>

        <!-- 活动详情 -->
        <div class="rule-detail">
          <div class="rule-detail-title"></div>
          <div class="activity_rules">
            <ul>
              <li>
                <span>1.</span>
                在申请此优惠前，请您先完善真实姓名、手机、银行卡等个人信息。
              </li>
              <li>
                <span>2.</span>
                今日有过任意一笔金额的存款，账号无异常情况。
              </li>
              <li>
                <span>3.</span>
                符合条件的会员可在比赛开始前5分钟报名，例如12:00开赛，参与截止时间为11:55分。
              </li>
              <li>
                <span>4.</span>
                奖励会在比赛结算后24小时内派发，系统自动发送，无需手工领取，1倍流水出款。
              </li>
              <li>
                <span>5.</span>
                瓜分规则为总奖金竞猜正确人数，例如总奖金1,000,000，有1,000人猜对，则猜对的会员每人可获得1,000,000
                / 1,000=1,000彩金。
              </li>
              <li>
                <span>6.</span>
                若发现有套利客户，对冲或不诚实获取盈利之行为，将取消其优惠资格。
              </li>
              <li>
                <span>7.</span>
                本活动只针对娱乐性质的会员，同一手机号码、电子邮箱、相同银行卡、同一个IP地址、同一通信地址、同一台设备，只能由一位会员使用，若发现重复行为，将无限期保留审核、扣回礼品及所产生的利润之权利。
              </li>
              <li>
                <span>8.</span>
                本活动中任何用户或团体以不正常的方式套取活动优惠，我司保留在不通知的情况下冻结或关闭账号使用的权力，并不退还款项，且用户会被列入黑名单。若发现有套利客户，对冲，或不诚实获取盈利之行为，将取消其优惠资格。
              </li>
              <li>
                <span>9.</span>
                为避免文字争议，此活动遵循一般活动规则与条款，并由贝宝保留最终解释权。
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="rule-bottom-img"></div>
    </div>
  </div>
</template>

<script>
import { getdata, vipActapply } from '../network/api'
import { Toast } from 'vant'
export default {
  data() {
    return {
      datalist: {},
      contentlist: [],
      isscrollTop: 'transparent',
      tabibdex: null,
      quiindex: null,
      cueTheme: '',
      listData: [
      ]
    }
  },
  computed: {
    optionHover() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  mounted() {
    let that = this
    this.getTasteMealInfo()
    this.cueTheme = window.localStorage.getItem('cueTheme')

    if (window.localStorage.getItem('opentype') == 'h5') {
      window.addEventListener('message', function (e) {
        // 数据返回
        if (e.data.act == 'taste') {
          if (e.data.status == 1) {
            that.getTasteMealInfo()
          }
        }
      })
    }
  },

  methods: {
    gotoback() {
      if (window.localStorage.getItem('opentype') == 'h5') {
        uni.navigateBack({})
      } else {
        uni.getEnv((res) => {
          uni.postMessage({
            data: {
              isclose: 'true',
            },
          })
        })
      }
    },
    getTasteMealInfo() {
      var _this = this
      let data = '?type=taste'
      getdata(data).then((res) => {
        this.datalist = res.message
        this.contentlist = res.message.content

        if(Object.prototype.toString.call(res.message.zjed_data) == '[object Object]'){
        const arr = Object.keys(res.message.zjed_data).map(key => res.message.zjed_data[key]);
        this.listData = arr
        }else{
        this.listData = res.message.zjed_data
        }
      })
    },
    pitchon(v, tabibdex, quiindex) {
      if (quiindex == 0) return
      if (this.datalist.pid != 0) return
      this.contentlist[tabibdex].forEach((v, i) => {
        v.check = false
      })
      this.contentlist[tabibdex][quiindex].check = true
      this.$forceUpdate()
    },

    quizactivities(type) {
      if (this.datalist.sj_zt == 0) {
        var count = ''
        var title = this.contentlist[type][0].name
        this.contentlist[type].forEach((v, i) => {
          if (v.check == true) {
            count = v.id
          }
        })
        if (count == '' || title == '') {
          Toast('请选择您预测的结果再提交竞猜,谢谢！')
          return
        }
        uni.getEnv((res) => {
          if (Object.keys(res)[0] === 'h5') {
            // Toast('该活动仅在app开发,请下载APP')
            window.parent.postMessage(
              {
                status: 'ok',
                params: {
                  urlApply: 'taste',
                  playid: count,
                  title: title,
                  urlType: window.localStorage.getItem('urlType'),
                },
              },
              '*'
            )
          } else {
            uni.postMessage({
              data: {
                urlApply: 'taste',
                playid: count,
                title: title,
              },
            })
          }
        })
      }
    },
  },
}
</script>

<style lang="less">
@import '../assets/css/rulestyle.less';

.seamless-box {
  padding: 10px 15px 20px 15px;
  .toptitle {
    padding: 10px 0;
    background: linear-gradient(0deg, #2a6afd, #63c6ff);
    display: flex;
    align-items: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    .item {
      font-size: 12px;
      flex: 1;
      padding: 0 2px;
      color: #fff !important;
      text-align: center;
      &:nth-child(1) {
        flex: 1.5;
      }
      &:nth-child(3) {
        flex: 1.5;
      }
    }
  }
  .seamless-warp2 {
  }
  .seamless-warp {
    max-height: 400px;
    overflow: hidden;
  }
  .seamless-item {
      // height: 45px;
      // min-height: 45px;
      display: flex;
      padding: 5px 0;
      align-items: center;
      border: 1px solid #eeeeee;

      .seamless-item1 {
        word-break:break-all;
        flex: 1;
        padding: 0 2px;
        font-size: 12px;
        text-align: center;
        &:nth-child(1) {
          flex: 1.5;
        }
        &:nth-child(3) {
          flex: 1.5 ;
        }
      }
    }
}

.van-icon .van-icon-arrow-left .van-nav-bar__arrow {
  color: #000 !important;
}

.van-nav-bar .van-icon {
  color: #000 !important;
}

body {
  background-color: #f7f8fa;
}

.dptBonus0 {
  font-family: PingFang SC;
  position: absolute;

  .activityRules {
    margin: 10px 15px;
    background-color: #fff;
    border-radius: 8px;
    padding: 10px 15px 20px 15px;
    padding-bottom: 5px;
    position: relative;
    z-index: 0;

    .title {
      width: 100%;
      padding: 10px 0;
      text-align: center;
      font-family: PingFang SC;
      font-weight: 400;
      color: #c8a57e;
      position: relative;
    }

    ul li {
      color: #000;
      margin-bottom: 16px;
      position: relative;
      padding-left: 16px;
      font-size: 14px;
      line-height: 1.5em;

      > span {
        text-align: center;
        position: absolute;
        top: 0px;
        left: 0;
      }
    }
  }

  .btn-box {
    height: 42px;
    line-height: 42px;
    text-align: center;
    border-radius: 5px;
    color: #fff;
    background: linear-gradient(0deg, #2a6afd, #63c6ff);
    margin: 25px 0px 15px 0px;
  }
}

.DptBonus-btns {
  width: 100%;
  height: 45px;
  background: linear-gradient(0deg, #2a6afd, #63c6ff);
  border-radius: 10px;
  line-height: 45px;
  text-align: center;
  color: #fff;
  margin: 20px auto;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 16px;
}

.quizactivities-top {
  .quizactivities-content {
    font-family: PingFang SC;
    font-size: 10px;
    width: 94%;
    background: url('../assets/img/pcbb/quizactivities-bg.png') no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .quizactivities-middle {
      margin: 0 10px;
      text-align: center;
      color: #fff;
      font-weight: 400;

      > div {
        margin: 2px 0;
      }

      .schedule {
        height: 20px;
        background-color: #fff;
        margin-bottom: 10px;
      }
    }

    .quizactivities-left {
      max-width: 50px;
      min-width: 50px;
      margin: 15px 0 10px 0;

      .quizactivities-img {
        margin: 0 auto;
        width: 40px;
        height: 40px;
        background-color: #000;
        border-radius: 50%;
        border: 3px solid #71d4f5;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .quizactivities-text {
        text-align: center;
        color: #fff;
        font-weight: 400;
        margin-top: 5px;
      }
    }
  }
}

.qui-content-box {
  display: flex;
  align-items: center;
  // justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;

  div {
    width: calc(100% / 3);
    border-bottom: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
    font-size: 12px;
    color: var(--td-colortext);
    text-align: center;
    height: 50px;
    display: flex;
    padding: 0 5px;
    align-items: center;
    justify-content: center;
    &:nth-child(1) {
      height: 40px;
      width: 100%;
      background: linear-gradient(90deg, rgb(69, 129, 250), rgb(99, 179, 252));
      color: #fff;
      text-align: center;
      padding: 8px 0;
      font-size: 13px;
      font-weight: 400;
      border-left: 1px solid #e9e9e9;
      border-right: 1px solid #e9e9e9;
      border-bottom: 0;
    }

    // &:nth-child(3n + 1) {
    //   border-right: 1px solid #e9e9e9;
    // }

    &:nth-child(3n - 1){
      border-left: 1px solid #e9e9e9;
    }
  }
}

.actqui {
  background: linear-gradient(90deg, rgb(69, 129, 250), rgb(99, 179, 252)) !important;
  color: #fff !important;
}

.actcolor1{
  color: #1a9c34 !important;
}
.actcolor2{
  color: red !important;
}

</style>
