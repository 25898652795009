import axios from 'axios'
import qs from 'qs'
export function request (config) {
  // 1.创建axios的实例
  const instance = axios.create({
    // baseURL: 'https://97842364-ja.cccc668.com/api/', //测试
    baseURL: '/api',
    timeout: 50000,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      // 'Authorization': 'Bearer' + '  ' + window.localStorage.getItem('access_token')
    },
  })

  instance.defaults.headers.post['Content-Type'] =
    'Content-Type:application/x-www-form-urlencoded; charset=UTF-8'

  // 2.axios的拦截器
  // 2.1.请求拦截的作用
  instance.interceptors.request.use(
    (config) => {
      if (config.method === 'post' && !(config.data instanceof FormData)) {
        config.data = qs.stringify(config.data)
      }

      // config.headers.Authorization = 'Bearer' + '  ' + "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2FwaS5iYXBvODE3NzYuY29tL2FwaS9hdXRob3JpemF0aW9ucyIsImlhdCI6MTcxNTc2NjE4NCwiZXhwIjoxNzE1Nzc2OTg0LCJuYmYiOjE3MTU3NjYxODQsImp0aSI6ImIzdXF6aVRDUnA1OWdJYmwiLCJzdWIiOjcwLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.vXbCNtzUfSwevveoWrvo3bgY9ezplQpgBvNKsKetTWo"

      config.headers.Authorization =
        'Bearer' + '  ' + window.localStorage.getItem('access_token')
      return config
    },
    (err) => {
      return Promise.reject(err)
    }
  )

  // 2.2.响应拦截
  instance.interceptors.response.use(
    (res) => {
      return res.data
    },
    (err) => {

      if (err && err.response) {
        switch (err.response.status) {
          case 400:
            console.log('错误请求')
            break
          case 401:
            /* window.location.href = '/#/login' */
            console.log('未授权，请重新登录')
            break
          case 403:
            console.log('拒绝访问')
            break
          case 404:
            console.log('请求错误,未找到该资源')
            break
          case 405:
            console.log('请求方法未允许')
            break
          case 408:
            console.log('请求超时')
            break
          case 500:
            console.log('服务器端出错')
            break
          case 501:
            console.log('网络未实现')
            break
          case 502:
            console.log('网络错误')
            break
          case 503:
            console.log('服务不可用')
            break
          case 504:
            console.log('网络超时')
            break
          case 505:
            console.log('http版本不支持该请求')
            break
          default:
            console.log(`连接错误${err.response.status}`)
        }
        return Promise.reject(err.response.data)
      } else {
        // window.location.href = '/#/login'
        console.log('连接到服务器失败', err.response)
        return Promise.reject(err.response.data)

      }
      return Promise.resolve(err.response)
    }
  )
  // 3.发送真正的网络请求
  return instance(config)
}
